import React, { useState } from "react";
import "./gallery.css";
import GalleryPop from "./gallerypop";
import r1 from '../../Assets/galleryimages/r1.jpg';
import r2 from '../../Assets/galleryimages/r2.jpg';
import r3 from '../../Assets/galleryimages/r3.jpg';
import r4 from '../../Assets/galleryimages/r4.jpg';
import r5 from '../../Assets/galleryimages/r5.jpg';
import r6 from '../../Assets/galleryimages/gallery.JPG';
import r7 from '../../Assets/galleryimages/gallery1.JPG';
import r8 from '../../Assets/galleryimages/gallery3.JPG';
import r9 from '../../Assets/galleryimages/gallery2.JPG';
const Gallery = () => {
    const [galleryPopup, setGalleryPopup] = useState(false);
  const [galleryPopup2, setGalleryPopup2] = useState(false);
   const [galleryPopup3, setGalleryPopup3] = useState(false);
   const [galleryPopup4, setGalleryPopup4] = useState(false);
   const [galleryPopup5, setGalleryPopup5] = useState(false);
//   const [galleryPopup6, setGalleryPopup6] = useState(false);
//   const [galleryPopup7, setGalleryPopup7] = useState(false);
//   const [galleryPopup8, setGalleryPopup8] = useState(false);
//   const [galleryPopup9, setGalleryPopup9] = useState(false);
//   const [galleryPopup10, setGalleryPopup10] = useState(false);
//   const [galleryPopup11, setGalleryPopup11] = useState(false);
//   const [galleryPopup12, setGalleryPopup12] = useState(false);
  const [galleryPopup13, setGalleryPopup13] = useState(false);
  const [galleryPopup14, setGalleryPopup14] = useState(false);
//   const [galleryPopup15, setGalleryPopup15] = useState(false);
  const [galleryPopup16, setGalleryPopup16] = useState(false);
  return (
    <div class="gallery_container">
      <div class="gallery_new">
        <div class="gallery__content">
          <h1 className="animate-hero">Gallery</h1>
        </div>
      </div>
      <div class="image-gallery">
        <div className="image-box">
          <img
            src={r8}
            alt="img.jpg"
            onClick={() => setGalleryPopup(true)}
          />
          <GalleryPop
            trigger={galleryPopup}
            setTrigger={setGalleryPopup}
            className="gallery_popup_container"
          >
            <div>
              <img src={r8} alt="" />
            </div>
          </GalleryPop>
        </div>
        <div className="image-box">
          <img
            src={r2}
            alt="img.jpg"
            onClick={() => setGalleryPopup13(true)}
          />
          <GalleryPop
            trigger={galleryPopup13}
            setTrigger={setGalleryPopup13}
            className="gallery_popup_container"
          >
            <div>
              <img src={r2} alt="" />
            </div>
          </GalleryPop>
        </div>
        <div className="image-box">
          <img
            src={r3}
            alt="img.jpg"
            onClick={() => setGalleryPopup16(true)}
          />
          <GalleryPop
            trigger={galleryPopup16}
            setTrigger={setGalleryPopup16}
            className="gallery_popup_container"
          >
            <div>
              <img src={r3} alt="" />
            </div>
          </GalleryPop>
        </div>
        <div className="image-box">
          <img
            src={r4}
            alt="img.jpg"
            onClick={() => setGalleryPopup2(true)}
          />
          <GalleryPop
            trigger={galleryPopup2}
            setTrigger={setGalleryPopup2}
            className="gallery_popup_container"
          >
            <div>
              <img src={r4} alt="" />
            </div>
          </GalleryPop>
        </div>
        <div className="image-box">
          <img
            src={r5}
            alt="img.jpg"
            onClick={() => setGalleryPopup14(true)}
          />
          <GalleryPop
            trigger={galleryPopup14}
            setTrigger={setGalleryPopup14}
            className="gallery_popup_container"
          >
            <div>
              <img src={r5} alt="" />
            </div>
          </GalleryPop>
        </div>  
        <div className="image-box">
          <img
            src={r7}
            alt="img.jpg"
            onClick={() => setGalleryPopup3(true)}
          />
          <GalleryPop
            trigger={galleryPopup3}
            setTrigger={setGalleryPopup3}
            className="gallery_popup_container"
          >
            <div>
              <img src={r7} alt="" />
            </div>
          </GalleryPop>
        </div>       
        <div className="image-box">
          <img
            src={r6}
            alt="img.jpg"
            onClick={() => setGalleryPopup3(true)}
          />
          <GalleryPop
            trigger={galleryPopup4}
            setTrigger={setGalleryPopup4}
            className="gallery_popup_container"
          >
            <div>
              <img src={r6} alt="" />
            </div>
          </GalleryPop>
        </div>       
        <div className="image-box">
          <img
            src={r9}
            alt="img.jpg"
            onClick={() => setGalleryPopup5(true)}
          />
          <GalleryPop
            trigger={galleryPopup5}
            setTrigger={setGalleryPopup5}
            className="gallery_popup_container"
          >
            <div>
              <img src={r9} alt="" />
            </div>
          </GalleryPop>
        </div>       
      </div>
    </div>
  )
}
export default Gallery