import React from 'react';
import About from '../component/About/about';
import Caro from '../component/Caro/index';
import Menu from '../component/Menu/menu';
import Specials from '../component/Specials/specials';

const Home = () => {
  return (
    <div>
        <Caro />
        <About />
        <Menu />
        <Specials />
    </div>
  )
}

export default Home;