import React from 'react'
import "./call.css";
import call from "../../Assets/call.png"
const Call = () => {
  return (
    <div class="phone-call">
				    <a href="tel:9921754460"> 
                    <img src={call} alt="" />
				    </a></div>
  )
}

export default Call