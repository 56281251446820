import React, { useEffect } from "react";
import "./about.css";
import Aos from "aos";
import "aos/dist/aos.css";
import aboutimg from "../../Assets/aboutimg/aboutimg.jpg";

const About = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div className="about_bg">
      <div className="about" >
        <h2 className="heading">About Us</h2>
        <div className="about_container">
          <div data-aos="fade-up" className="about_card">
            <img
              src={aboutimg}
              alt=""
            />
          </div>
          <div className="about_card">
            <div className="card_2">
              <h2 data-aos="fade-up" className="about_heading">About Rajwada Inn</h2>
              <p data-aos="fade-up">The Complete Family & Business Class Hotel<br/>The Rajwada Inn Satara is set 12 kilometer
              from Satara.<br/>The Complete Family & Business Class Hotel<br/>The Rajwada Inn Satara is set 12 kilometer
              from Satara.</p>
              {/* <div className="about_btn">about us</div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
