import React,{ useEffect } from 'react'
import './specials.css';
import Carousel from "react-bootstrap/Carousel";
import Aos from "aos";
import "aos/dist/aos.css";
import s1 from '../../Assets/specials/matanthali.jpg';
import s2 from '../../Assets/specials/chikenthali.jpg';

const Specials = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (    
    <div className="special_bg">
    <div className="about" >
      <h2 className="new_heading">Check Our Specials</h2>
      <Carousel fade>
    <Carousel.Item >
      <div className="specials_container">
        
          <div className='borderspl'>
            </div>
        <div data-aos="fade-up" className='special_info'>
          <h1>Mutton thali </h1>
          <p className='title_para'>अस्सल स्पेशल महाराष्ट्रीयन मटनाची थाळी</p>
          <p className='para'>Mutton Thali is very popular for the Sunday lunch & dinner menu. The mutton thali comprised of mutton fry, aalni, a bowl of Mutton rassas. A robust flavor comes through in the Mutton Thali because the mutton is cooked in very savory stock. The Mutton Thali is complete with a Mutton Sukka.
</p>
          </div>
        <div data-aos="fade-up">
          <img src={s1} className='special_img'/>
          </div>
      </div>
      </Carousel.Item>
      <Carousel.Item>
      <div className="specials_container">
        
          <div className='borderspl'>
            </div>
        <div data-aos="fade-up" className='special_info'>
          <h1>chicken thali</h1>
          <p className='title_para'>सातारा स्पेशल झणझणीत चिकन थाळी</p>
          <p className='para'>Weekends are always the time you get with your family and friends to be one with, and it is the food that unites them to make it very memorable. Make your family and friends try this meal of delighted food
</p>
          </div>
        <div data-aos="fade-up">
          <img src={s2} className='special_img'/>
          </div>
      </div>
  </Carousel.Item>
  </Carousel>
  </div>
  </div>
 
  )
}

export default Specials