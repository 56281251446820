import React,{ useEffect } from "react";
import "./menu.css";
import Aos from "aos";
import "aos/dist/aos.css";

const Menu = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <div className="menu_bg">
      <h2>Check Our Tasty Menu</h2>
      <div className="menu">
        <div className="menu_container">
          <div data-aos="fade-up" className="menu_card">
            <div className="menu_div">
              <img
                src="https://pbs.twimg.com/media/C6-K1VPWkAAQ8k5.jpg"
                alt=""
                className="menu_img"
              />
              <div className="title_menu">
                <div>
                  <h3>Mutton Thaali</h3>
                  <div className="border"></div>
                  <p>
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                    Et, tempora!
                  </p>
                </div>
                <div>
                  <p className="price_menu">₹370/-</p>
                </div>
              </div>
            </div>
          </div>
          <div data-aos="fade-up" className="menu_card">
            <div className="menu_div">
              <img
                src="https://i.ytimg.com/vi/-Ye6Z9-lysA/maxresdefault.jpg"
                alt=""
                className="menu_img"
              />
              <div className="title_menu">
                <div>
                  <h3>Chiken rara</h3>
                  <div className="border"></div>
                  <p>
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                    Et, tempora!
                  </p>
                </div>
                <div>
                  <p className="price_menu">₹320/-</p>
                </div>
              </div>
            </div>
          </div>
          <div data-aos="fade-up" className="menu_card">
            <div className="menu_div">
              <img
                src="https://www.poodina.com/wp-content/uploads/2021/10/2021-03-22.jpg"
                alt=""
                className="menu_img"
              />
              <div className="title_menu">
                <div>
                  <h3>Paneer Dilruba</h3>
                  <div className="border"></div>
                  <p>
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                    Et, tempora!
                  </p>
                </div>
                <div>
                  <p className="price_menu">₹280/-</p>
                </div>
              </div>
            </div>
          </div>
          <div data-aos="fade-up" className="menu_card">
            <div className="menu_div">
              <img
                src="https://www.poodina.com/wp-content/uploads/2021/10/2021-03-09.jpg"
                alt=""
                className="menu_img"
              />
              <div className="title_menu">
                <div>
                  <h3>Mutton boti kabab</h3>
                  <div className="border"></div>
                  <p>
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                    Et, tempora!
                  </p>
                </div>
                <div>
                  <p className="price_menu">₹340/-</p>
                </div>
              </div>
            </div>
          </div>
          <div data-aos="fade-up" className="menu_card">
            <div className="menu_div">
              <img
                src="https://www.indianhealthyrecipes.com/wp-content/uploads/2018/08/mutton-keema-recipe.jpg"
                alt=""
                className="menu_img"
              />
              <div className="title_menu">
                <div>
                  <h3>Mutton kheema Masala</h3>
                  <div className="border"></div>
                  <p>
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                    Et, tempora!
                  </p>
                </div>
                <div>
                  <p className="price_menu">₹310/-</p>
                </div>
              </div>
            </div>
          </div>
          <div data-aos="fade-up" className="menu_card">
            <div className="menu_div">
              <img
                src="https://www.poodina.com/wp-content/uploads/2021/10/2020-12-12.jpg"
                alt=""
                className="menu_img"
              />
              <div className="title_menu">
                <div>
                  <h3>Gaoran Chicken thali</h3>
                  <div className="border"></div>
                  <p>
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                    Et, tempora!
                  </p>
                </div>
                <div>
                  <p className="price_menu">₹310/-</p>
                </div>
              </div>
            </div>
          </div>
          <div data-aos="fade-up" className="menu_card">
            <div className="menu_div">
              <img
                src="http://pkmfoods.com/image/cache/catalog/recipes/Product%20images-09-740x500.jpg"
                alt=""
                className="menu_img"
              />
              <div className="title_menu">
                <div>
                  <h3>Akkha Masoor</h3>
                  <div className="border"></div>
                  <p>
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                    Et, tempora!
                  </p>
                </div>
                <div>
                  <p className="price_menu">₹160/-</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
