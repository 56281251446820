import React, { useEffect } from 'react'
import './event.css';
import Aos from "aos";
import "aos/dist/aos.css";
import apshinge from "../../Assets/turism/apshinge.jpeg"

const Event = () => {
    useEffect(() => {
        Aos.init({ duration: 1000 });
      }, []);
  return (
    <div>
    <div className="tourism_new">
        <div className="tourism__content">
            <h1>Tourism</h1>
        </div>
    </div>
    <div className='tourism'>
        <div className="tourism_container">
            <div data-aos="fade-down-right" className="tourism_card">
                <h2 className='tourism_heading'>Janki Agro Tourism</h2>
                <p className='tourism_para2'>Janki agro-tourism is a farm cum education cum entertainment place especially for young urban children who are away from village life . It has a big farm of 8 acres with rooms for stay,a swimming pool, pond for boating, bridge made with ropes and wooden planks , rain dance, good veg food & above all Maharashtrian hospitality.. overall enjoyable!</p>
            </div>
            <div data-aos="flip-left" className="tourism_card">
                <img src='https://dynamic-media-cdn.tripadvisor.com/media/photo-o/0e/cb/87/bc/enjoy-with-clean-water.jpg?w=500&h=400&s=1' alt="" className='tourism_img' />
            </div>
        </div><br/>
        <div className="tourism_container">
            <div data-aos="flip-right" className="tourism_card">
                <img src={apshinge} alt="" className='tourism_img' />
            </div>
            <div data-aos="fade-down-left" className="tourism_card">
                <h2 className='tourism_heading'>Apshinge Militory Village</h2>
                <p className='tourism_para2'>Apshringe Military is a small village in Satara district but it has historical significance as at least one member of every family in the village has served or is serving in the armed forces. The contribution of the village was recognised by the British government, which installed a memorial in the village in the memory of 46 soldiers who laid down their lives fighting for Britain during World War I.</p>
            </div>
        </div>
    </div>
</div>
)
}
export default Event
