import React, { useState } from "react";
import { Link } from "react-router-dom";
 import "./index.css";
import oglogo from '../../Assets/oglogo.png'

const Header = () => {
  //scroll header logic
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 100) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  //header logic
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  const closeMobileMenu = () => setClick(false);

  //Dropdown logic
  const [show, setShow] = useState(false);

  const showDropdown = (e) => {
    setShow(!show);
  };
  const hideDropdown = (e) => {
    setShow(false);
  };

  //dropdown css inline
  const myStyle = {
    fontSize: "20px",
    color: "#000",
    zIndex: "1000000",
    textDecoration: "none",
  };
  return (
    <div className={navbar ? "header scroll" : "header"}>
      <div className="header__left">
       <Link to="/">
          <img src={oglogo} alt="" className="header_logo" />
        </Link> 
      </div>
      <div className="header_right">
        <div className="header__icons">
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <Link to="/" onClick={closeMobileMenu}>
              Home
            </Link>
            {/* <Link to="/about" onClick={closeMobileMenu}>
              About Us
            </Link>            
            <Link to="/menu" onClick={closeMobileMenu}>
              Menu
            </Link>
            <Link to="/specials" onClick={closeMobileMenu}>
              Specials
            </Link> */}
            <Link to="/gallery" onClick={closeMobileMenu}>
              Gallery
            </Link>      
            <Link to="/events" onClick={closeMobileMenu}>
              Tourism
            </Link>
            <Link to="/contact" onClick={closeMobileMenu}>
              Contact Us
            </Link>           
          </ul>
          <div className="menu_icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header