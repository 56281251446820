import React from 'react';
import './App.css';
import Header from './component/Header/index';
import About from './component/About/about';
import Gallery from './component/Gallery/gallery';
import Menu from './component/Menu/menu';
import Event from './component/Event/event';
import Specials from './component/Specials/specials';
import Contact from './component/Contact/contact';
import Footer from './component/Footer/footer';
import Watsapp from './component/Watsapp/watsapp';
import Call from './component/Callnow/call';
import Home from './Pages/Home';
import { HashRouter as Router , Routes , Route , Link } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <Router>
      <Header />
      <Watsapp />
      <Call />
        <Routes>
          <Route path="/" element={<Home />}/>
          <Route path="/about" element={<About />}/>
          <Route path="/menu" element={<Menu />}/>
          <Route path="/gallery" element={<Gallery />}/>
          <Route path="/specials" element={<Specials />}/>
          <Route path="/events" element={<Event />}/>
          <Route path="/contact" element={<Contact />}/>
          <Route path="/watsapp" element={<Watsapp />}/>
        </Routes>
      <Footer />
      </Router>
      
    </div>
  );
}

export default App;
