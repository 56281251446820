import React, { useEffect } from "react";
import "./contact.css";
import Aos from "aos";
import "aos/dist/aos.css";

const Contact = () => {
    useEffect(() => {
        Aos.init({ duration: 2000 });
      }, []);
  return (
    <div>
    <div data-aos="flip-left" className="contact-in contact">
      <div className="contact_container">
        <div className="contact_card">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15213.327050440466!2d74.04555116929913!3d17.586973970541735!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x48e12de5f2d01b0!2sHotel%20Rajwada%20Inn!5e0!3m2!1sen!2sin!4v1646293414483!5m2!1sen!2sin"
            width="600"
            height="450"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </div>

        <form className="contact_card">
          <h2 className="contact_h2">Connect With Us</h2>

          <input
            className="text field"
            placeholder="Enter Your Name"
            autoComplete="off"
            required
          />
          <input
            className="text field"
            placeholder="Enter Your Email'Id"
            autoComplete="off"
            required
          />
          <input
            className="number field"
            placeholder="Enter Your Mobile Number"
            autoComplete="off"
            required
          />
          <textarea
            placeholder="Message"
            className="field"
            autoComplete="off"
            required
          ></textarea>
          <button className="btn_contact" type="submit">
            Send
          </button>
        </form>
      </div>
    </div>
  </div>
  )
}

export default Contact