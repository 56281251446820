import React from 'react';
import './caro.css';
import Carousel from "react-bootstrap/Carousel";
import rajwada4 from "../../Assets/caro/rajwada4.jpg";
import rajwada1 from "../../Assets/caro/rajwada1.jpg";
import rajwada2 from "../../Assets/caro/rajwada2.jpg";
import rajwada3 from "../../Assets/caro/rajwada3.jpg";
const Caro = () => {
  return (
    <div>
      <Carousel fade>
        <Carousel.Item className="caro_up">
          <img className="d-block w-100 caro_img" src={rajwada4} alt="" />
          <div className="caro_word">
            <h2 className="caro_h2">Welcome To Hotel Rajwada Inn...</h2>
            <p className="caro_p">Delivering great food for more than 5 years!</p> 
          </div>
        </Carousel.Item>
        {/* <Carousel.Item>
          <img className="d-block w-100 caro_img" src='https://scontent-bom1-2.xx.fbcdn.net/v/t1.6435-9/59987860_2249797168670536_5909640640110526464_n.jpg?_nc_cat=109&ccb=1-5&_nc_sid=8bfeb9&_nc_ohc=_citiPHXNe8AX8Z9INB&_nc_ht=scontent-bom1-2.xx&oh=00_AT-9wQXaCAuqdTIV09KhNiNUoxOXzDXchDG9rs0rakaAGQ&oe=624563E0' alt="" />
          <div className="caro_word">
            <h2 className="caro_h2">To Infinity & Beyond</h2>
            <p className="caro_p">Your Time To Escape</p>
          </div>
        </Carousel.Item> */}
        <Carousel.Item className="slider--container">
          <img className="d-block w-100 caro_img" src={rajwada1} alt="" />
          <div className="caro_word">
          <h2 className="caro_h2">Welcome To Hotel Rajwada Inn...</h2>
          <p className="caro_p">Delivering great food for more than 5 years!</p> 
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100 caro_img" src={rajwada2} alt="" />
          <div className="caro_word">
          <h2 className="caro_h2">Welcome To Hotel Rajwada Inn...</h2>
          <p className="caro_p">Delivering great food for more than 5 years!</p> 
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100 caro_img" src={rajwada3} alt="" />
          <div className="caro_word">
          <h2 className="caro_h2">Welcome To Hotel Rajwada Inn...</h2>
             <p className="caro_p">Delivering great food for more than 5 years!</p> 
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default Caro;
