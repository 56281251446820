import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import footerLogo from "../../Assets/footerLogo.png";

const Footer = () => {
  return (
    <div>
    <div className="footer">
      <div className="footer_container">
        <div className="footerLinks">
          <img src={footerLogo} alt="" />
        </div>
        <div className="footerLinks">
          <p>
            <span className="address">Address</span><br/> 
            A/P Borgaon 415519, <br/>Tal - Satara, Dist - Satara,
            Maharashtra INDIA.
          </p>
          <li className="social">
            <Link to="/">
              <i class="fab fa-instagram"></i> 
            </Link>
          </li>
          <li className="social">
            <Link to="/">
              <i class="fab fa-facebook-square"></i> 
            </Link>
          </li>
          <li className="social">
            <Link to="/">
              <i class="fab fa-youtube"></i> 
            </Link>
          </li>
        </div>
        <div className="footerLinks">
          <h2>Connect With Us</h2>
          <li>
            <Link to="/contact">Contact Us</Link>
          </li>
          <p>Phone: +91 99217 54460<br/>
          Email: </p>
          
        </div>
        <div className="footerLinks">
          <h2>Useful Links</h2>
          <li>
            <Link to="/about">About Us</Link>
          </li>
          <li>
            <Link to="/menu">Menu</Link>
          </li>
          <li>
            <Link to="/specials">Specials</Link>
          </li>
          <li>
            <Link to="/gallery">Gallery</Link>
          </li>
          <li>
            <Link to="/events">Tourism</Link>
          </li>
          <li>
            <Link to="/contact">Contact Us</Link>
          </li>          
        </div>
      </div>
    </div>
    <div className="two_links">
      <div>
        <h3 className="copy_h3">Hotel Rajwada Inn &copy;2022</h3>
      </div>
      <div>
        <a href='https://stylotech.in/' target="_blank" className="company_name" rel="norefrer">Designed By: <span>StyloTech</span></a>
      </div>
    </div>
  </div>
  );
};

export default Footer;
