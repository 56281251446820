import React from 'react'
import "./watsapp.css";
import { Link } from "react-router-dom";
import wa from "../../Assets/wa.png"
const Watsapp = () => {
  return (
    <div>
    <div className="whatsapp_main">
      <a href="https://wa.me/9921754460" target="_blank" rel="noreferrer">
        <img src={wa} alt="" />
      </a>
    </div>
  </div>
  )
}

export default Watsapp